var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-btn',{staticClass:"float-right",attrs:{"to":"/fkadmin"}},[_vm._v(" Admin Dashboard ")]),_c('h2',[_vm._v("NFT Admin")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.nfts,"item-key":"mintedTokenId","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.mintedTokenId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mintedTokenId)+" ("+_vm._s(item.status)+")"),_c('br'),_vm._v(" "+_vm._s(item.id)),_c('br'),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.singleRemint(item.starDustPlayerId, item.token_template_id, 1, item.id )}}},[_vm._v(" Remint ")]),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.singleBurn(item.starDustPlayerId, item.mintedTokenId, 1, item.id)}}},[_vm._v(" Burn ")])]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ownerName)),_c('br'),_c('small',[_vm._v("UserID:"),_c('br'),_vm._v(" "+_vm._s(item.owner))]),_c('br'),_c('small',[_vm._v("SD ID:"),_c('br'),_vm._v(" "+_vm._s(item.starDustPlayerId))]),_c('br'),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.burnAll(item.starDustPlayerId, item.id)}}},[_vm._v(" Burn All ")])]}},{key:"item.last_updated",fn:function(ref){
var item = ref.item;
return [(item.last_updated)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.last_updated.seconds,"dddd MMM Do"))+" ")]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [(item.created)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created.seconds,"dddd MMM Do"))+" ")]):_vm._e()]}}])}),_c('hr'),_c('v-btn',{on:{"click":_vm.createSdUser}},[_vm._v(" Re-create users (migrate to new chain) ")]),_c('v-btn',{on:{"click":_vm.reCreateTemplates}},[_vm._v(" Re-create all NFTs templates (migrate to new chain) ")]),_c('v-btn',{on:{"click":_vm.removeDupeTemplates}},[_vm._v(" Remove Duplicate Token Templates & update template Id's ")]),_c('v-btn',{on:{"click":_vm.reMintAllNFTs}},[_vm._v(" Re-mint All NFTs ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }