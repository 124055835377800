<template>
  <v-card>
    <v-card-text>
      <v-btn
        class="float-right"
        to="/fkadmin"
      >
        Admin Dashboard
      </v-btn>
      <h2>NFT Admin</h2>
      <v-data-table
        :headers="headers"
        :items="nfts"
        item-key="mintedTokenId"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.mintedTokenId="{ item }">
          {{ item.mintedTokenId }} ({{ item.status }})<br>
          {{ item.id }}<br>
          <v-btn
            color="error"
            small
            class="ma-1"
            @click="singleRemint(item.starDustPlayerId, item.token_template_id, 1, item.id )"
          >
            Remint
          </v-btn>
          <v-btn
            color="error"
            small
            class="ma-1"
            @click="singleBurn(item.starDustPlayerId, item.mintedTokenId, 1, item.id)"
          >
            Burn
          </v-btn>
        </template>
        <template v-slot:item.owner="{ item }">
          {{ item.ownerName }}<br>
          <small>UserID:<br> {{ item.owner }}</small><br>
          <small>SD ID:<br> {{ item.starDustPlayerId }}</small><br>
          <v-btn
            color="error"
            small
            class="ma-1"
            @click="burnAll(item.starDustPlayerId, item.id)"
          >
            Burn All
          </v-btn>
        </template>
        <template v-slot:item.last_updated="{ item }">
          <span v-if="item.last_updated">
            {{ item.last_updated.seconds|moment("dddd MMM Do") }}
          </span>
        </template>
        <template v-slot:item.created="{ item }">
          <span v-if="item.created">
            {{ item.created.seconds|moment("dddd MMM Do") }}
          </span>
        </template>
      </v-data-table>

      <hr>
      <v-btn
        @click="createSdUser"
      >
        Re-create users (migrate to new chain)
      </v-btn>
      <v-btn
        @click="reCreateTemplates"
      >
        Re-create all NFTs templates (migrate to new chain)
      </v-btn>
      <v-btn
        @click="removeDupeTemplates"
      >
        Remove Duplicate Token Templates & update template Id's
      </v-btn>

      <v-btn
        @click="reMintAllNFTs"
      >
        Re-mint All NFTs
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/functions'
import axios from 'axios'
import dbFunctions from '@/db'
import tokenFunctions from '@/tokens'

export default {
  data() {
    return {
      user: this.$store.state.user,
      allUsers: [],
      search: '',
      nfts: [],
    }
  },
  computed: {
    headers() {
      return [
        {text: 'Mint ID', value: 'mintedTokenId'},
        {text: 'Template ID', value: 'token_template_id'},
        {text: 'Name', value: 'name'},
        {text: 'Owner', value: 'owner'},
        {text: 'Created', value: 'created'},
        {text: 'Last Update', value: 'last_updated'},
        {text: 'Selling', value: 'selling'},
        {text: 'Buy Now', value: 'buyNow'},
        {text: 'Min Offer', value: 'minOffer'},
        {text: 'Bids', value: 'bids'},
        {text: 'Purchased', value: 'purchase_price'},
        {text: 'Current', value: 'current_price'},
      ]
    },
  },
  mounted() {
    this.getNFTs()
  },
  methods: {
    getNFTs() {
      dbFunctions.getAllMintedFighters()
        .then(async querySnapshot => {
          this.nfts = []
          querySnapshot.forEach(doc => {
            const data = doc.data()
            data.id = doc.id
            this.nfts.push(data)
          })

          await dbFunctions.getAllUserProfile()
            .then(users => {
              users.forEach(doc => {
                this.allUsers[doc.id] = doc.data()
              })
            })

          /*
          const getAllUsers = firebase.functions().httpsCallable('getAllUsers')
          getAllUsers().then(data => {
            console.log(data.data)
            for (let i = 0; i < data.data.length; i += 1) {
              console.log(data.data[i])
              this.updateSdIds(data.data[i].uid, this.allUsers[data.data[i].uid].starDustPlayerId)
            }
          })
          */
          for (let i = 0; i < this.nfts.length; i += 1) {
            this.userItem(this.nfts[i].owner, i)
            this.nfts[i].starDustPlayerId = this.allUsers[this.nfts[i].owner].starDustPlayerId
          }
        })
    },
    reCreateTemplates() {
      if (window.confirm('re-mint all nfts !?!')) {
        for (let i = 0; i < this.nfts.length; i += 1) {
          delete this.nfts[i].id
          delete this.nfts[i].mintedTokenId
          delete this.nfts[i].token_template_id
          delete this.nfts[i].owner
          delete this.nfts[i].ownerName
          delete this.nfts[i].email
          delete this.nfts[i].purchase_price
          delete this.nfts[i].current_team
          console.log(this.nfts[i])

          const templateData = {
            name: this.nfts[i].name,
            cap: this.nfts[i].cap || '10000',
            type: 'NFT',
            props: this.nfts[i],
            slug: this.nfts[i].slug,
            card_type: this.nfts[i].card_type || 'base',
          }
          tokenFunctions.createTokenTemplate(templateData)
            .then(templateId => {
              console.log(templateId)
            }).catch(err => console.log(err))
        }
      }
    },
    async removeDupeTemplates() {
      const fighterIds = []
      const allTemplates = {}
      const getAllTokenTemplates = firebase.functions().httpsCallable('getAllTokenTemplates')
      console.log('get first 100 templates')

      async function batch(num) {
        console.log('batch')
        console.log(parseInt(num, 10))
        const templates = await getAllTokenTemplates(parseInt(num, 10))
        console.log(templates.data)
        for (let i = 0; i < templates.data.length; i += 1) {
          fighterIds.push(templates.data[i].props.mutable.fighter_id)
          allTemplates[templates.data[i].props.mutable.fighter_id] = templates.data[i].id
        }
      }

      await batch(parseInt(0, 10))
      await batch(parseInt(100, 10))
      await batch(parseInt(200, 10))
      await batch(parseInt(300, 10))

      console.log(fighterIds)
      const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
      const dupes = findDuplicates(fighterIds)
      console.log('dupes')
      console.log(dupes.length)

      for (let i = 0; i < dupes.length; i += 1) {
        console.log(allTemplates[dupes[i]])
        const deleteTokenTemplate = firebase.functions().httpsCallable('deleteTokenTemplate')
        deleteTokenTemplate(allTemplates[dupes[i]])
      }

      console.log(allTemplates)
      const failed = []
      for (let i = 0; i < this.nfts.length; i += 1) {
        console.log(allTemplates[this.nfts[i].fighter_id])
        console.log(this.nfts[i].id)
        dbFunctions.updateMintedFighter(this.nfts[i].id, {
          token_template_id: allTemplates[this.nfts[i].fighter_id],
        }).then(data => {
          console.log(data)
        }).catch(err => {
          console.log(err)
        })
        dbFunctions.updateFighter(this.nfts[i].slug, {
          token_template_id: allTemplates[this.nfts[i].fighter_id],
        }).then(data => {
          console.log(data)
        }).catch(err => {
          failed.push(this.nfts[i].slug)
          console.log(err)
        })
      }

      console.log(failed)

      return allTemplates
    },

    async singleRemint(playerId, tokenId, amount, mintedFighterId) {
      tokenFunctions.mintToken(playerId, tokenId, amount)
        .then(mintedTokenId => {
          console.log(mintedTokenId[0])
          const fighterFields = {
            token_template_id: tokenId,
            mintedTokenId: mintedTokenId[0],
          }
          dbFunctions.updateMintedFighter(mintedFighterId, fighterFields)
        })
    },
    async singleBurn(playerId, mintedTokenId, amount, mintedFighterId) {
      tokenFunctions.burnToken(playerId, mintedTokenId, amount)
        .then(burnData => {
          console.log(burnData)
          const fighterFields = {
            mintedTokenId: false,
          }
          dbFunctions.updateMintedFighter(mintedFighterId, fighterFields)
        })
    },
    async burnAll(playerId, mintedFighterId) {
      if (window.confirm('Burn all nfts for this user !?!')) {
        const url = `http://localhost:4000/fantasykombat/us-central1/getInventory?playerId=${playerId}`
        axios({
          method: 'get',
          url,
        }).then(data => {
          for (let i = 0; i < data.data.length; i += 1) {
            console.log(data.data[i])
            this.singleBurn(playerId, data.data[i].tokenId, data.data[i].amount, mintedFighterId)
          }
        })
      }
    },
    async reMintAllNFTs() {
      if (window.confirm('remint all nfts !?!')) {
        await dbFunctions.getAllUserProfile()
          .then(users => {
            users.forEach(doc => {
              this.allUsers[doc.id] = doc.data()
            })
          })
        for (let i = 0; i < this.nfts.length; i += 1) {
          this.singleRemint(this.allUsers[this.nfts[i].owner].starDustPlayerId, this.nfts[i].token_template_id, 1, this.nfts[i].id)
        }
      }
    },
    updateSdIds(uid, oldSdId) {
      const getPlayerId = firebase.functions().httpsCallable('getPlayerId')
      const data = {uid}
      getPlayerId(data)
        .then(sdData => {
          console.log('sd id')
          console.log(sdData.data.playerId)
          const user = {uid}
          dbFunctions.updateUsersProfile(user, {
            starDustPlayerId: sdData.data.playerId,
            starDustPlayerSolanaId: oldSdId,
          }).then(profile => console.log(profile))
        })
    },
    createSdUser() {
      console.log('create new SD user')
      if (window.confirm('Create new StarDust Users !?!')) {
        dbFunctions.getAllUserProfile()
          .then(users => {
            users.forEach(doc => {
              let data = {}
              data = doc.data()
              data.uid = doc.id
              const createUser = firebase.functions().httpsCallable('getUser')
              createUser(data)
                .then(udata => {
                  const userData = {
                    uniqueId: data.userUID,
                    userData: {
                      email: udata.data.email,
                    },

                  }
                  this.SDCreateUser(userData, data.starDustPlayerId)
                })
            })
          })
      }
    },
    SDCreateUser(user, oldSdId) {
      console.log('start bulk create')
      console.log(user)
      const createUser = firebase.functions().httpsCallable('createUser')
      createUser(user)
        .then(data => {
          console.log(data)
          dbFunctions.updateUsersProfile(user, {
            starDustPlayerId: data.data.playerId,
            starDustPlayerSolanaId: oldSdId,
          })
        })
    },
    userItem(userUid, i) {
      let memberData = {}
      const member = firebase.functions().httpsCallable('getUser')
      member({uid: userUid})
        .then(user => {
          memberData = user
          this.nfts[i].ownerName = memberData.data.displayName
          this.nfts[i].email = memberData.data.email

          return memberData.data.displayName
        })

      return memberData
    },
  },
}
</script>

<style lang="scss">
</style>
